import React, { useState } from 'react';
import { FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import PivotNavBar from './PivotNavbar';
import MapView from './mapElement';
import NotificationsPanel from '../../layoutElements/notification';
import { useTranslation } from 'react-i18next';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import SignalCellularAltIcon from '@mui/icons-material/SignalCellularAlt';
import './irrigation.css'
import NotificationItem from '../homePageMain/notificationNew'
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import RotateRightIcon from '@mui/icons-material/RotateRight';
import RotateLeftIcon from '@mui/icons-material/RotateLeft';
import WaterDropOutlinedIcon from '@mui/icons-material/WaterDropOutlined';
import CloseIcon from '@mui/icons-material/Close';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import StopIcon from '@mui/icons-material/Stop';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';

const MultiSectionLayout = () => {
    const { t } = useTranslation();
  const [selectedPivot, setSelectedPivot] = useState('');
  const navigate = useNavigate();

  const handlePivotSelect = (event) => {
    setSelectedPivot(event.target.value);
    navigate(`/home/irrigation/${event.target.value.toLowerCase()}`);
  };

  return (
    <div
      style={{
        display: 'flex',
        height: '86vh',
        gap: '10px',
        padding: '10px',
        boxSizing: 'border-box',
        flexDirection: 'column', // Arrange rows in a column
      }}
    >
      {/* First Row (40-60 Width, Custom Height Distribution) */}
      <div
        style={{
          display: 'flex',
          gap: '10px',
          flex: 0.7, // Custom height distribution for the first row
        }}
      >
        {/* Left Section 1 (40% of width) */}
        <div
          style={{
            flex: 4, // 40% of the width
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            marginLeft:"4px"
          }}
        >
          <FormControl
            fullWidth
            sx={{
              width: '100%',
            }}
          >
            <InputLabel
              sx={{
                fontSize: '0.8rem',
                lineHeight: '0.8rem',
              }}
            >
              Please Select Pivot
            </InputLabel>
            <Select
              value={selectedPivot}
              onChange={handlePivotSelect}
              label="Please Select Pivot"
              sx={{
                height: '40px',
                fontSize: '14px',
                '& .MuiSelect-select': {
                  
                  fontWeight: 'bold',
                },
              }}
            >
              <MenuItem value="pivotA">Pivot A</MenuItem>
              <MenuItem value="pivotB">Pivot B</MenuItem>
            </Select>
          </FormControl>
        </div>

        {/* Right Section 1 (60% of width) */}
        <div
          style={{
            flex: 6, // 60% of the width
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <div className="pivot-navbarContainer">
            <PivotNavBar />
          </div>
        </div>
      </div>

      {/* Second Row (60-40 Width, Custom Height Distribution) */}
      <div
        style={{
          display: 'flex',
          gap: '10px',
          flex: 6.3, // Custom height distribution for the second row
        }}
      >
        {/* Left Section 2 (60% of width) */}
        <div
          style={{
            flex: 6, // 60% of the width
            //backgroundColor: 'hsl(220, 70%, 90%)',
            //border: '1px solid black',
            //borderRadius: '8px',
            //padding: '10px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <MapView></MapView>
        </div>

        {/* Right Section 2 (40% of width) */}
        <div
          style={{
            flex: 4, // 40% of the width
            //backgroundColor: 'hsl(300, 70%, 90%)',
            //border: '1px solid #afabab',
            //borderRadius: '8px',
            marginButton: "0%",
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
            <div className='grid-containerVerticalPivot'>

            <div className='sectionContainer'>
                    <div className='sectionTitle' style={{ fontFamily: 'Open Sans, sans-serif', fontSize: '16px', fontWeight: 'bold' }}>Status
                        <Stack direction="row" spacing={2}>
                            <Button variant="contained" startIcon={<StopIcon />} sx={{ textTransform: 'none', fontFamily: 'Nunito Sans, sans-serif', fontSize: '16px', fontWeight: 'bold', backgroundColor: '#0E8140', width: '120px', height: '30px', '&:hover': { backgroundColor: '#94b634' } }}>OFF</Button>
                            <Button variant="contained" startIcon={<CalendarMonthIcon />} sx={{ textTransform: 'none', fontFamily: 'Nunito Sans, sans-serif', fontSize: '16px', fontWeight: 'bold', backgroundColor: '#757575', width: '120px', height: '30px', '&:hover': { backgroundColor: '#6b6b6b' } }}>Calendar</Button>
                            <Button variant="contained" startIcon={<PlayArrowIcon />} sx={{ textTransform: 'none', fontFamily: 'Nunito Sans, sans-serif', fontSize: '16px', fontWeight: 'bold', backgroundColor: '#757575', width: '120px', height: '30px', '&:hover': { backgroundColor: '#6b6b6b' } }}>Start</Button>
                        </Stack>
                    </div>
            </div>

            <div className='sectionContainer'>
                    <div className='sectionTitle' style={{ fontFamily: 'Open Sans, sans-serif', fontSize: '16px', fontWeight: 'bold' }}>Direction
                        <Stack direction="row" spacing={2}>
                            <Button variant="contained" startIcon={<RotateRightIcon />} sx={{ textTransform: 'none', fontFamily: 'Nunito Sans, sans-serif', fontSize: '16px', fontWeight: 'bold', backgroundColor: '#757575', width: '120px', height: '30px', '&:hover': { backgroundColor: '#6b6b6b' } }}>Forward</Button>
                            <Button variant="contained" startIcon={<RotateLeftIcon />} sx={{ textTransform: 'none', fontFamily: 'Nunito Sans, sans-serif', fontSize: '16px', fontWeight: 'bold', backgroundColor: '#0E8140', width: '120px', height: '30px', '&:hover': { backgroundColor: '#94b634' } }}>Reverse</Button>
                        </Stack>
                    </div>
            </div>

            <div className='sectionContainer'>
                    <div className='sectionTitle' style={{ fontFamily: 'Open Sans, sans-serif', fontSize: '16px', fontWeight: 'bold' }}>Pressure Control
                        <Stack direction="row" spacing={2}>
                            <Button variant="contained" startIcon={<CloseIcon />} sx={{ textTransform: 'none', fontFamily: 'Nunito Sans, sans-serif', fontSize: '16px', fontWeight: 'bold', backgroundColor: '#0E8140', width: '120px', height: '30px', '&:hover': { backgroundColor: '#94b634' } }}>Dry</Button>
                            <Button variant="contained" startIcon={<WaterDropOutlinedIcon />} sx={{ textTransform: 'none', fontFamily: 'Nunito Sans, sans-serif', fontSize: '16px', fontWeight: 'bold', backgroundColor: '#757575', width: '120px', height: '30px', '&:hover': { backgroundColor: '#6b6b6b' } }}>Wet</Button>
                        </Stack>
                    </div>
            </div>

            <div className='sectionContainer'>
                    <div className='sectionTitle' style={{ fontFamily: 'Open Sans, sans-serif', fontSize: '16px', fontWeight: 'bold' }}>Speed
                        <Stack direction="row" spacing={2}>
                            <Button variant="contained" sx={{ textTransform: 'none', fontFamily: 'Nunito Sans, sans-serif', fontSize: '16px', fontWeight: 'bold', backgroundColor: '#0E8140', width: '120px', height: '30px', '&:hover': { backgroundColor: '#94b634' } }}>Panel</Button>
                            <Button variant="contained" startIcon={<RotateLeftIcon />} sx={{ textTransform: 'none', fontFamily: 'Nunito Sans, sans-serif', fontSize: '16px', fontWeight: 'bold', backgroundColor: '#757575', width: '120px', height: '30px', '&:hover': { backgroundColor: '#6b6b6b' } }}>Sectors</Button>
                        </Stack>
                    </div>
            </div>

                    <div className='sectionTitle' style={{ fontFamily: 'Open Sans, sans-serif', fontSize: '16px', fontWeight: 'bold' }}>Stop on Slot
                        <Stack direction="row" spacing={2}>
                            <Button variant="contained" sx={{ textTransform: 'none', fontFamily: 'Nunito Sans, sans-serif', fontSize: '16px', fontWeight: 'bold', backgroundColor: '#0E8140', width: '120px', height: '30px', '&:hover': { backgroundColor: '#94b634' } }}>10.1°</Button>
                            <Button variant="contained" sx={{ textTransform: 'none', fontFamily: 'Nunito Sans, sans-serif', fontSize: '16px', fontWeight: 'bold', backgroundColor: '#757575', width: '120px', height: '30px', '&:hover': { backgroundColor: '#6b6b6b' } }}>0°</Button>
                            <Button variant="contained" sx={{ textTransform: 'none', fontFamily: 'Nunito Sans, sans-serif', fontSize: '16px', fontWeight: 'bold', backgroundColor: '#757575', width: '120px', height: '30px', '&:hover': { backgroundColor: '#6b6b6b' } }}>0°</Button>
                            <Button variant="contained" sx={{ textTransform: 'none', fontFamily: 'Nunito Sans, sans-serif', fontSize: '16px', fontWeight: 'bold', backgroundColor: '#757575', width: '120px', height: '30px', '&:hover': { backgroundColor: '#6b6b6b' } }}>0°</Button>
                        </Stack>
                    </div>
                </div>

        </div>
      </div>

      {/* Third Row (60-40 Width, Custom Height Distribution) */}
      <div
        style={{
          display: 'flex',
          gap: '10px',
          flex: 7, // Custom height distribution for the third row
        }}
      >
        {/* Left Section 3 (60% of width) */}
        <div
          style={{
            flex: 6, // 60% of the width
           
          }}
        >
            <div className='irrigationStatus'>
          <div className='titleMainPagePivotDown'>Pivot Status</div>

<div className='pivotStatusDetailTable'>
    <div></div>
    <div className='pivotStatusDetailLeft'>
        <div className="pivotStatusDetailItem">
            <span>{t("end pressure")}:</span>
            <span className="pivotStatusDetailValue">0 bar</span>
            <InfoOutlinedIcon className="pivotStatusDetailIcon" style={{ marginRight: '0.5rem' }} />
        </div>
        <div className="pivotStatusDetailItem">
            <span>{t("speed set point")}:</span>
            <span className="pivotStatusDetailValue">Waiting</span>
            <InfoOutlinedIcon className="pivotStatusDetailIcon" style={{ marginRight: '0.5rem' }} />
        </div>
        <div className="pivotStatusDetailItem">
            <span>{t("actual speed")}:</span>
            <span className="pivotStatusDetailValue">0 %</span>
            <InfoOutlinedIcon className="pivotStatusDetailIcon" style={{ marginRight: '0.5rem' }} />
        </div>
        <div className="pivotStatusDetailItem">
            <span>{t("angle")}:</span>
            <span className="pivotStatusDetailValue">12.4</span>
            <InfoOutlinedIcon className="pivotStatusDetailIcon" style={{ marginRight: '0.5rem' }} />
        </div>
        <div className="pivotStatusDetailItem">
            <span>{t("pressure control")}:</span>
            <span className="pivotStatusDetailValueDRY">DRY</span>
            <InfoOutlinedIcon className="pivotStatusDetailIcon" style={{ marginRight: '0.5rem' }} />
        </div>
        <div className="pivotStatusDetailItem">
            <span>{t("end gun")}:</span>
            <span className="pivotStatusDetailValueGPS">GPS OFF</span>
            <InfoOutlinedIcon className="pivotStatusDetailIcon" style={{ marginRight: '0.5rem' }} />
        </div>
        <div className="pivotStatusDetailItem">
            <span>{t("next stop")}:</span>
            <span className="pivotStatusDetailValue">Stopped</span>
            <InfoOutlinedIcon className="pivotStatusDetailIcon" style={{ marginRight: '0.5rem' }} />
        </div>
    </div>
    <div></div>
    <div className='pivotStatusDetailLeft'>
        <div className="pivotStatusDetailItem">
            <span>{t("wire cut alarm")}:</span>
            <span className="pivotStatusDetailValueOK">OK</span>
            <InfoOutlinedIcon className="pivotStatusDetailIcon" style={{ marginRight: '0.5rem' }} />
        </div>
        <div className="pivotStatusDetailItem">
            <span>{t("power supply")}:</span>
            <span className="pivotStatusDetailValueOK">OK</span>
            <InfoOutlinedIcon className="pivotStatusDetailIcon" style={{ marginRight: '0.5rem' }} />
        </div>
        <div className="pivotStatusDetailItem">
            <span>{t("GPS status")}:</span>
            <span className="pivotStatusDetailValueOK">OK</span>
            <InfoOutlinedIcon className="pivotStatusDetailIcon" style={{ marginRight: '0.5rem' }} />
        </div>
        <div className="pivotStatusDetailItem">
            <span>{t("cellular signal level")}:</span>
            <SignalCellularAltIcon className="pivotStatusDetailValue" />
            <InfoOutlinedIcon className="pivotStatusDetailIcon" style={{ marginRight: '0.5rem' }} />
        </div>
        <div className="pivotStatusDetailItem">
            <span>{t("running hours")}:</span>
            <span className="pivotStatusDetailValue">465h</span>
            <InfoOutlinedIcon className="pivotStatusDetailIcon" style={{ marginRight: '0.5rem' }} />
        </div>
        <div className="pivotStatusDetailItem">
            <span>{t("irrigation hours")}:</span>
            <span className="pivotStatusDetailValue">442h</span>
            <InfoOutlinedIcon className="pivotStatusDetailIcon" style={{ marginRight: '0.5rem' }} />
        </div>
        <div className="pivotStatusDetailItem">
            <span>{t("flowmeter")}:</span>
            <span className="pivotStatusDetailValue">0m³/h - 20 456 m³</span>
            <InfoOutlinedIcon className="pivotStatusDetailIcon" style={{ marginRight: '0.5rem' }} />
        </div>
    </div>
    <div></div>
</div>
</div>
        </div>

        {/* Right Section 3 (40% of width) */}
        <div
          style={{
            flex: 4,
            //backgroundColor: 'hsl(270, 70%, 80%)',
            maxHeight: '310px', // Restricts to parent container height
            overflowY: 'auto', // Enables scrolling
          }}
        >
          <NotificationItem />
        </div>
      </div>
    </div>
  );
};

export default MultiSectionLayout;
