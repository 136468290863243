import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';


const NotificationItem = ({ date, status, message }) => {
    const statusColors = {
        INFO: '#2082A4',
        WARNING: 'orange',
        ERROR: 'red',
        ACTIVITY: '#F5E050',
    };

    const getStatusStyle = (status) => {
        return {
            fontFamily: 'Nunito Sans',
            fontSize: '12px',
            color: 'white',
            backgroundColor: statusColors[status],
            fontWeight: 'bold',
            textAlign: 'center',
            padding: '4px 2px',
            borderRadius: '4px',
            cursor: 'pointer',
            marginRight: '10px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            minWidth: '80px',
            
        };
    };

    return (
        <div style={{
            display: 'flex',
            alignItems: 'center',
            padding: '10px',
            borderBottom: '1px solid #ddd',
            gap: '10px',
             
        }}>
            <div style={{ flex: 3 }}>{date}</div>
            <div style={{ flex: 2 }}>
                <div style={getStatusStyle(status)}>
                    {status}
                </div>
            </div>
            <div style={{ flex: 3 }}>{message}</div>
        </div>
    );
};

const NotificationsSatellite = () => {
    const { t } = useTranslation();

    // State for filtering
    const [showFilter, setShowFilter] = useState(false);
    const [selectedPivot, setSelectedPivot] = useState('');
    const [selectedTemperature, setSelectedTemperature] = useState('');
    const [selectedStatus, setSelectedStatus] = useState('');


    const notifications = [
        { date: 'March 27, 2024', status: 'INFO', message: t('EOS Data OK') },
        { date: 'March 27, 2024', status: 'INFO', message: t('EOS Data High') },
        { date: 'March 27, 2024', status: 'WARNING', message: t('No Sat Image Available') },
        { date: 'March 27, 2024', status: 'WARNING', message: t('EOS Data Low') },
        { date: 'March 27, 2024', status: 'ACTIVITY', message: t('Settings Changed') },
        { date: 'March 27, 2024', status: 'INFO', message: t('EOS Data High') },
        { date: 'March 27, 2024', status: 'INFO', message: t('EOS Data High') },
        { date: 'March 27, 2024', status: 'WARNING', message: t('No Sat Image Available') },
        { date: 'March 27, 2024', status: 'INFO', message: t('EOS Data OK') },
        { date: 'March 27, 2024', status: 'INFO', message: t('EOS Data OK') },
        { date: 'March 27, 2024', status: 'ACTIVITY', message: t('Settings Changed') },
        { date: 'March 27, 2024', status: 'WARNING', message: t('EOS Data Low') },
        { date: 'March 27, 2024', status: 'WARNING', message: t('EOS Data Low') },
        { date: 'March 27, 2024', status: 'INFO', message: t('EOS Data OK') },
        { date: 'March 27, 2024', status: 'ACTIVITY', message: t('Settings Changed') },
        { date: 'March 27, 2024', status: 'INFO', message: t('EOS Data OK') },
        { date: 'March 27, 2024', status: 'INFO', message: t('EOS Data High') },
        { date: 'March 27, 2024', status: 'WARNING', message: t('No Sat Image Available') },
        { date: 'March 27, 2024', status: 'WARNING', message: t('EOS Data Low') },
        { date: 'March 27, 2024', status: 'ACTIVITY', message: t('Settings Changed') },
        { date: 'March 27, 2024', status: 'INFO', message: t('EOS Data High') },
        { date: 'March 27, 2024', status: 'INFO', message: t('EOS Data High') },
        { date: 'March 27, 2024', status: 'WARNING', message: t('No Sat Image Available') },
        { date: 'March 27, 2024', status: 'INFO', message: t('EOS Data OK') },
        { date: 'March 27, 2024', status: 'INFO', message: t('EOS Data OK') },
        { date: 'March 27, 2024', status: 'ACTIVITY', message: t('Settings Changed') },
        { date: 'March 27, 2024', status: 'WARNING', message: t('EOS Data Low') },
        { date: 'March 27, 2024', status: 'WARNING', message: t('EOS Data Low') },
        { date: 'March 27, 2024', status: 'INFO', message: t('EOS Data OK') },
        { date: 'March 27, 2024', status: 'ACTIVITY', message: t('Settings Changed') },

    ];
    // Handle Filter Logic
    const filteredNotifications = notifications.filter((notification) => {
        const pivotMatch = selectedPivot ? notification.pivot.includes(selectedPivot) : true;
        const temperatureMatch = selectedTemperature ? notification.temperature.includes(selectedTemperature) : true;
        const statusMatch = selectedStatus ? notification.status.includes(selectedStatus) : true;
        return pivotMatch && temperatureMatch && statusMatch;
    });

    // Function to handle filter toggle
    const toggleFilter = () => {
        setShowFilter(!showFilter);
    };

    // Function to clear filters
    const clearFilters = () => {
        setSelectedPivot('');
        setSelectedTemperature('');
        setSelectedStatus('');
    };

    // Function to close filter modal and apply filters
    const applyFilters = () => {
        setShowFilter(false);
    };

    return (
        <div   style={{
            maxHeight: '60vh', // Take up all the height provided by the parent
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            border: '1px solid #afabab',
            boxShadow: '0px 0px 5px rgba(0, 0, 0, 0.3)',
            borderRadius: '8px',
            overflow: 'hidden', // Ensure overflow is managed
            marginTop: '0.5%',
            position: 'relative',
            height: '100%', // Ensure the component takes up all available height
            boxSizing: 'border-box', // Include padding in the height
          }}>
            <div style={{
                padding: '10px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                position: 'relative'
            }}>
                <div style={{
                    backgroundColor: '#A6CE39',
                    color: 'white',
                    padding: '4px 30px',
                    fontFamily: 'Nunito Sans',
                    fontSize: '20px',
                    textAlign: 'center',
                    borderRadius: '8px',
                    boxShadow: '0px 0px 5px rgba(0, 0, 0, 0.3)',
                }}>
                    {t('notifications')}
                </div>

                {/* Search icon for filter */}
                <div style={{ position: 'absolute', right: '10px', cursor: 'pointer' }} onClick={toggleFilter}>
                    <SearchIcon style={{ color: '#A6CE39' }} />
                </div>
            </div>

            {/* Filter modal centered within the notifications panel */}
            {showFilter && (
                <div style={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)', // Center it inside the notifications panel
                    backgroundColor: 'white',
                    padding: '15px 15px', // Reduced top padding to make less space above Filter Options
                    borderRadius: '8px',
                    boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.3)',
                    width: '250px',
                    zIndex: 1, // Ensure it appears above the notification list
                }}>

                    {/* Close icon */}
                    <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <CloseIcon
                            onClick={toggleFilter} // Close the modal when clicked
                            style={{ cursor: 'pointer', color: '#A6CE39' }}
                        />
                    </div>

                    <h3 style={{ fontFamily: 'Open Sans', textAlign: 'center', marginTop: '5px' /* Reduce space on top */ }}>{t('Filter Options')}</h3>

                    {/* Device Name Filter */}
                    <label style={{ fontFamily: 'Nunito Sans', fontSize: '14px' }}>{t('Device Name')}:</label>
                    <input
                        type="text"
                        value={selectedPivot}
                        onChange={(e) => setSelectedPivot(e.target.value)}
                        placeholder={t('Enter pivot')}
                        style={{ width: '100%', padding: '8px', marginBottom: '10px', borderRadius: '4px', border: '1px solid #ccc', fontFamily: 'Open Sans' }}
                    />

                    {/* Status Filter */}
                    <label style={{ fontFamily: 'Nunito Sans', fontSize: '14px' }}>{t('Status')}:</label>
                    <select
                        value={selectedStatus}
                        onChange={(e) => setSelectedStatus(e.target.value)}
                        style={{ width: '100%', padding: '8px', borderRadius: '4px', border: '1px solid #ccc', marginBottom: '10px' }}
                    >
                        <option value="">{t('Select status')}</option>
                        <option value="INFO">INFO</option>
                        <option value="WARNING">WARNING</option>
                        <option value="ERROR">ERROR</option>
                        <option value="ACTIVITY">ACTIVITY</option>
                    </select>

                    {/* Apply and Clear Buttons */}
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <button
                            onClick={clearFilters}
                            style={{
                                backgroundColor: '#ccc',
                                padding: '8px 20px',
                                borderRadius: '6px',
                                cursor: 'pointer',
                                border: 'none', // Remove the line (border)
                                fontFamily: 'Nunito Sans',
                                fontWeight: 'bold'
                            }}
                        >
                            {t('Clear')}
                        </button>
                        <button
                            onClick={toggleFilter}
                            style={{
                                backgroundColor: '#A6CE39',
                                color: 'white',
                                padding: '8px 20px',
                                borderRadius: '6px',
                                cursor: 'pointer',
                                border: 'none', // Remove the line (border)
                                fontFamily: 'Nunito Sans',
                                fontWeight: 'bold'
                            }}
                        >
                            {t('Apply')}
                        </button>
                    </div>
                </div>
            )}

            {/* Notifications List */}
            <div style={{
                flex: 1,
                overflow: 'auto', // Allows for scrolling
                fontFamily: 'Open Sans',
                fontSize: '10px',
                fontWeight: '600',
                // Hide scrollbar for WebKit browsers (Chrome, Safari)
                '-webkit-overflow-scrolling': 'touch',
                scrollbarWidth: 'none', // For Firefox
                msOverflowStyle: 'none', // For Internet Explorer and Edge
            }}>
                {filteredNotifications.map((notification, index) => (
                    <NotificationItem key={index} {...notification} />
                ))}
            </div>
        </div>
    );
};

export default NotificationsSatellite;