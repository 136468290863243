import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import './satellite.css'

import GradientLine from './gradient';
import LineChartSat from './satLineChart';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { useParams } from 'react-router-dom';
import { useToken } from '../../services/useToken';
import { useFetchFields } from '../../hooks/fetchSingeField';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import TextField from '@mui/material/TextField';
import MyMapComponent from './mapElement';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import Checkbox from '@mui/material/Checkbox';
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import dayjs from 'dayjs';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import Modal from './settingsSatImage';
import { fetchFieldsData } from './fetch_api_fields';
import NotificationsSatellite from './notificationSatellite';




const Satellite = () => {
    const { id } = useParams();
    const [selectedDataset, setSelectedDataset] = useState(null);
    const token = useToken()
    const [datasets, setDatasets] = useState([]);
    const [error, setError] = useState(null);

    const { dataField, loadin, erro } = useFetchFields(token, id);
    const [indexType, setIndexType] = useState('ndvi');
    const [value, setValue] = useState(null);
    const [datasetsDate, setDatasetsDate] = useState([]);
    let discardedDates = [];

    const [deviceData, setDeviceData] = useState(null);
    const [loading, setLoading] = useState(false);
    const [errorFields, setErrorFields] = useState(false);

    const navigate = useNavigate();

    useEffect(() => {
        async function fetchData() {
            setLoading(true);
            try {
                const data = await fetchFieldsData(token);
                setDeviceData(data);

            } catch (e) {
                setError(e);
            } finally {
                setLoading(false);
            }
        }

        if (token) {
            fetchData();
        }
    }, [token]);

    const isDateAvailable = (date) => {
        // Create a new Date object to avoid mutating the original date
        let adjustedDate = new Date(date);

        // Subtract one day
        adjustedDate.setDate(adjustedDate.getDate() + 1);
        const formattedDate = adjustedDate.toISOString().split('T')[0];
        //return datasetsDate.includes(formattedDate);
        return datasetsDate.includes(formattedDate) && !discardedDates.includes(formattedDate);
    };

    const handleChangeView = (event) => {

    };

    const [isNormChecked, setIsNormChecked] = useState(false);
    const handleCheckboxNormChange = (event) => {
        const selectedValue = event.target.value;
        setIsNormChecked(selectedValue === 'Normalized');
    };

   

    const handleChangeIndex = (event) => {
        setIndexType(event.target.value);
    };

    useEffect(() => {
        //console.log("Date or Index Type changed");
        const formattedDate = value ? value.format('YYYY-MM-DD') : null;
        //console.log('Fetching data for date:', value, 'and index type:', indexType);

        async function fetchData() {
            try {
                const headers = new Headers({
                    'Authorization': `Token ${token}`
                });

                let url = `https://nabuapplication.com/api/satelliteimage/${id}`;
                const params = new URLSearchParams();

                if (formattedDate) {
                    params.append('date', formattedDate);
                }
                if (indexType) {
                    params.append('index_type', indexType);
                }

                const response = await fetch(`${url}?${params.toString()}`, { headers });
                const data = await response.json();

                setSelectedDataset(data[0]); // Set the dataset based on the fetched data

                //console.log("Fetched data:", data);

            } catch (e) {
                setError(e);
            }
        }

        if (value || indexType) {
            fetchData();
        }
    }, [value, indexType]); // Depend on both value and indexType

    const handleDateChangePicker = (newValue) => {
        //console.log("Date picker value:", newValue);
        setValue(newValue);
    };

    const handleSelectionChange = (deviceId) => {
        navigate(`/home/sattview/${deviceId}`);
    };


    useEffect(() => {
        async function fetchData() {
            try {
                const headers = new Headers({
                    'Authorization': `Token ${token}`
                });

                const response = await fetch(`https://nabuapplication.com/api/satelliteimage/${id}?index_type=ndvi`, { headers });
                const data = await response.json();

                // Once data is fetched, set the first dataset as the default selected dataset
                if (data.length > 0) {
                    setSelectedDataset(data[0]);


                }
                if (data.length > 0) {
                    setDatasets(data);
                    for (let i = 0; i < data.length; i++) {
                        // Check if the average is not 1
                        if (data[i].average !== 1) {
                            setSelectedDataset(data[i]);
                            return;  // Exit the function once the appropriate dataset is found
                        }
                    }
                    // this part done for temp because of eleminating broken data. in future it is better to drop them from backend or db side above loop checks if data broken which is avg is 1 and takes previos data to load on page
                    //setSelectedDataset(data[0]);  
                    //console.log("data:",data[1])
                }
            } catch (e) {
                setError(e);
            }
        }

        fetchData(); // This invokes the fetchData function.
    }, [id, token]);

    //console.log(selectedDataset)


    const [age, setAge] = React.useState('');
    const handleChange = (e) => {
        setAge(e.target.value);
    };

    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const toggleDropdown = () => {
        setIsDropdownOpen(!isDropdownOpen);
    };

    const { t } = useTranslation();


    const [dateCompare, setDateCompare] = useState(null);
    const [selectedDatasetDate, setSelectedDatasetDate] = useState(null);
    useEffect(() => {
        async function fetchData() {
            try {
                const headers = new Headers({
                    'Authorization': `Token ${token}`
                });

                // Update the URL to match your backend endpoint
                const response = await fetch(`https://nabuapplication.com/api/satelliteimagedates/available_dates/?field_info_id=${id}`, { headers });
                const data = await response.json();

                // The dates are in the 'dates' key of the response
                if (data.dates && data.dates.length > 0) {
                    //console.log("dates heree")
                    //console.log(data.dates)
                    setDatasetsDate(data.dates);
                    //setSelectedDateInfo(data.dates[0]);
                } else {
                    // Handle the case where there are no dates
                    setDatasetsDate([]);
                    setSelectedDatasetDate(null);
                }
            } catch (e) {
                setError(e);
            }
        }

        fetchData(); // This invokes the fetchData function.
    }, [id, token]); // Dependencies for useEffect

    const [Etc, setEtc] = useState(null);

    useEffect(() => {
        if (value) {
            //console.log("valuechanged")
            const formattedDate = value.format('YYYY-MM-DD');
            async function fetchData() {
                try {
                    const headers = new Headers({
                        'Authorization': `Token ${token}`
                    });

                    const response = await fetch(`https://nabuapplication.com/api/etc/?field_info_id=${id}&date=${formattedDate}`, { headers });
                    const data = await response.json();

                    // Assuming the API returns the dataset for the selected date
                    setEtc(data[0]);    // this is the element that we are rendering !!!!!!!!!
                    //console.log("fetched etc")
                    //console.log(data)
                    //console.log("assigned etc")
                    //console.log(Etc)


                } catch (e) {
                    setError(e);
                }
            }

            fetchData();
        }
    }, [value, indexType, selectedDataset, token]);


    const [showModal, setShowModal] = useState(false);
    const handleSettings = () => {
        setShowModal(true);
    };

    const handleCloseModal = () => {
        setShowModal(false);
    };

    const [currentTime, setCurrentTime] = useState(new Date());
    const formatDate = (date) => {
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed
        const year = date.getFullYear();
        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');
        const seconds = String(date.getSeconds()).padStart(2, '0');
    
        return `${day}/${month}/${year} ${hours}:${minutes}:${seconds}`;
      };

       // Set initial state to the first device ID if deviceData is available
  const [selectedDevice, setSelectedDevice] = useState('');

  // Use useEffect to set default value once deviceData is available
  useEffect(() => {
    if (deviceData && deviceData.length > 0) {
      setSelectedDevice(deviceData[0].id); // Default to the first device ID
    }
  }, [deviceData]);

  const handleChangeTitle = (event) => {
    const selectedId = event.target.value;
    setSelectedDevice(selectedId);
    handleSelectionChange(selectedId);
  };
    return (
        <div className='satelliteTitle'>

            <div className="satellite-container">
                <div
                    className="titleSatPage"
                    onClick={toggleDropdown}
                    style={{
                        padding: '10px',
                        width: '800px',
                        position: 'relative',
                    }}
                >
                    <FormControl fullWidth>
                        <InputLabel>{t("Select Your Field")}</InputLabel>
                        <Select
                            value={selectedDevice}
                            onChange={handleChangeTitle}
                            label={t("Select Your Field")}
                        >
                            {deviceData?.map(device => (
                                <MenuItem key={device.id} value={device.id}>
                                    {device.name} {t("Satellite Data")}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </div>

               
            </div>

            <div className='sattPageMainDivUp'>
                
                <div className='satellitePageLeftMainDiv'>
                <div className='satPageLeftMainUpNew'>
                    <div className='sattImaDropDown'>
                        <div></div>
                        <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-label">Select View</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={age}
                                label="Select View"
                                onChange={handleChangeView}
                            >
                                <MenuItem value={'10'}>Single View</MenuItem>
                                <MenuItem value={'20'}>Compare View</MenuItem>
                            </Select>
                        </FormControl>
                        <div></div>
                        <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-label">Select Index</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={indexType}
                                label="Select View"
                                onChange={handleChangeIndex}
                            >
                                <MenuItem value={'ndvi'}>NDVI</MenuItem>
                                <MenuItem value={'ndre'}>NDRE</MenuItem>
                                <MenuItem value={'ndmi'}>NDMI</MenuItem>
                            </Select>
                        </FormControl>
                        <div></div>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            {/*<DatePicker
                        label="Controlled picker"
                        value={value}
                        onChange={(newValue) => {
                            setValue(newValue);
                        }}
                        renderInput={(params) => <TextField {...params} />}
                    /> */}
                            <DatePicker
                                label="Select a Date"
                                value={value}
                                onChange={handleDateChangePicker}
                                renderInput={(params) => <TextField {...params} />}
                                shouldDisableDate={(date) => !isDateAvailable(date)}
                            />
                            {/*onChange={(newValue) => {
                            setValue(newValue);
                            handleDateChangePicker
                            
                            const formattedDate = newValue.format('YYYY-MM-DD');
                            console.log(formattedDate)
                            const dataset = datasets.find(d => d.date === formattedDate);
                            setSelectedDataset(dataset);
                        }}
                    */}
                        </LocalizationProvider>
                        <div></div>
                            <FormGroup
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'center',  // Centers horizontally
                                    alignItems: 'center',      // Centers vertically (if needed)
                                    marginLeft: '2px',         // Optional: keep this if you want slight left margin
                                    width: '100%',             // Ensure the FormGroup takes up the full width of the parent
                                }}
                            >
                                
                                <FormControl fullWidth>
                                    <InputLabel>Select Type</InputLabel>
                                    <Select
                                        value={isNormChecked ? 'Normalized' : 'Basic'} // Determine the selected value based on the state
                                        onChange={handleCheckboxNormChange}
                                        label="Select Type"
                                    >
                                        <MenuItem value="Basic">Basic</MenuItem>
                                        <MenuItem value="Normalized">Normalized</MenuItem>
                                    </Select>
                                </FormControl>
                            </FormGroup>

                    </div>

                    <div className='mapViewBatuSat'>
                        {selectedDataset ? (
                            <div>
                                {selectedDataset.cloud_percent > 15 && (
                                    <div style={{ textAlign: 'center' }}>
                                        <div style={{ backgroundColor: '#fff3cd', color: '#856404', padding: '10px', border: '2px solid #ffeeba', borderRadius: '5px', margin: '10px auto', marginLeft: '20%', marginRight: '20%' }}>
                                            Warning: Cloud percentage is high!
                                        </div>
                                    </div>
                                )}
                                <div className='mapControl'>
                                    <MyMapComponent
                                        key={selectedDataset.image_data} // Replace with a unique field from your dataset
                                        bounds={selectedDataset.bounds}
                                        imageBase64={isNormChecked ? selectedDataset.image_data_norm : selectedDataset.image_data}
                                        
                                    />
                                </div>
                            </div>
                        ) : (
                            <div>Loading...</div>
                        )}
                    </div>
                    <GradientLine />
                    <div></div>
                </div>
                
                <div className='satellitePageLeftGraph'>
                <Box sx={{ minWidth: 100, fontFamily: 'Open Sans, sans-serif', display: 'flex', justifyContent: 'center', marginTop: '2%' }}>
                            <FormControl
                                sx={{ width: '30%', fontFamily: 'Open Sans, sans-serif' }}  // Adjust width and center
                            >
                                <InputLabel id="demo-simple-select-label" sx={{ fontFamily: 'Open Sans, sans-serif' }}>{t("Select Curve")}</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={age}
                                    label="Age"
                                    onChange={handleChange}
                                    sx={{ fontFamily: 'Open Sans, sans-serif' }}
                                    className="customSelect"
                                >
                                    <MenuItem value={10} sx={{ fontFamily: 'Open Sans, sans-serif' }}>{t("Actual Vegetation")}</MenuItem>
                                    <MenuItem value={20} sx={{ fontFamily: 'Open Sans, sans-serif' }}>{t("Vegetation Analysis")}</MenuItem>
                                    <MenuItem value={30} sx={{ fontFamily: 'Open Sans, sans-serif' }}>{t("Moisture Analysis")}</MenuItem>
                                </Select>
                            </FormControl>
                        </Box>
                        <div className='lineChartWrapper'>
                            <LineChartSat></LineChartSat>
                        </div>
                    </div>
                </div>
                
                <div className='satellitePageRightMainDiv'>
                <div className='grid-containerVerticalUp'>
                    {selectedDataset ? (
                        <div className='satBoxUp'>
                            <div className='sattSettings'>
                                <div style={{ fontSize: '12px', marginTop: '1%', fontWeight: '600', fontFamily: 'Open Sans',marginLeft: '8%' }}>{formatDate(currentTime)}</div>
                                {/*<div style={{ fontSize: '12px', marginTop: '1%', marginLeft: '5%', fontWeight: '600', fontFamily: 'Open Sans', color: 'grey' }}> {selectedDataset.date}</div> */} 
                                <div className='iconWrap' onClick={handleSettings}>
                                    <SettingsOutlinedIcon></SettingsOutlinedIcon>
                                </div>
                                {showModal && (
                                    <Modal onClose={handleCloseModal}>
                                        {/* Content of the modal, such as the settings form */}
                                        {/* Add your settings form components here */}
                                    </Modal>
                                )}
                            </div>
                            <div></div>
                            <div className='satelliteSelectedUp'>
                                <div></div>
                                <div className='WSboxLayerSatellite'>
                                    <div></div>
                                    <div className='WSboxInnerSatellite' style={{ fontSize: '16px', fontWeight: '700', fontFamily: 'Open Sans', textAlign: 'left' }}>
                                    <div className='' style={{ fontSize: '18px', fontWeight: '600', fontFamily: 'Open Sans', textAlign: 'left', marginTop:'2%' }}>{t("Crop")}</div>
                                    <div></div>
                                    <div className='' style={{ fontSize: '24px', fontWeight: '700', fontFamily: 'Open Sans', textAlign: 'left' }}>{dataField ? (dataField.crop_type ? dataField.crop_type : 'None') : ''}</div>
                                        </div>
                                    <div></div>
                                    <div className='WSboxInnerSatellite' style={{ fontSize: '16px', fontWeight: '700', fontFamily: 'Open Sans', textAlign: 'left' }}>
                                    <div className='' style={{ fontSize: '18px', fontWeight: '600', fontFamily: 'Open Sans', textAlign: 'left',marginTop:'2%' }}>Etc</div>
                                        <div></div>
                                        <div className='' style={{ fontSize: '24px', fontWeight: '700', fontFamily: 'Open Sans', textAlign: 'left' }}>{Etc ? (
                                        Etc.Etc === 99999 ? (
                                            <ReportProblemIcon style={{ color: 'orange', paddingTop: '8px', paddingLeft: '10px', paddingBottom: '8px', fontSize: '3.5vh' }} />
                                        ) :
                                            <strong>
                                                <div className='dataCardDataElements'>{Etc.Etc.toFixed(2)}mm</div>
                                            </strong>) : (<strong>
                                                <div className='dataCardDataElements'>-</div>
                                            </strong>)}</div></div>
                                    <div></div>
                                </div>
                                <div> </div>
                                <div className='WSboxLayerSatellite'>
                                    <div></div>
                                    <div className='WSboxInnerSatellite' style={{ fontSize: '16px', fontWeight: '700', fontFamily: 'Open Sans', textAlign: 'center' }}>
                                        <div className='dataCardTitle' style={{ fontSize: '18px', fontWeight: '600', fontFamily: 'Open Sans', textAlign: 'left', marginTop:'2%' }}>{t("Average Vegetation")} </div>
                                        <div></div>
                                        <div className='dataCardValue' style={{ fontSize: '24px', fontWeight: '700', fontFamily: 'Open Sans', textAlign: 'left' }}>{selectedDataset.average < 0
                                        ? "0%"
                                        : `${Math.round(selectedDataset.average * 100)}%`} </div>
                                    </div>

                                    <div></div>

                                    <div className='WSboxInnerSatellite' style={{ fontSize: '16px', fontWeight: '700', fontFamily: 'Open Sans', textAlign: 'center' }}>
                                        <div className='dataCardTitle' style={{ fontSize: '18px', fontWeight: '600', fontFamily: 'Open Sans', textAlign: 'left', marginTop:'2%' }}>{t("Median Vegetation")}</div> 
                                        <div></div>
                                        <div className='dataCardValue' style={{ fontSize: '24px', fontWeight: '700', fontFamily: 'Open Sans', textAlign: 'left' }}>{selectedDataset.median < 0
                                        ? "0%"
                                        : `${Math.round(selectedDataset.median * 100)}%`}</div>
                                        </div>

                                    <div></div>

                                </div>
                                <div> </div>
                                <div className='WSboxLayerSatellite'>
                                    <div></div>
                                    <div className='WSboxInnerSatellite' style={{ fontSize: '16px', fontWeight: '700', fontFamily: 'Open Sans', textAlign: 'center' }}>
                                        <div className='dataCardTitle' style={{ fontSize: '18px', fontWeight: '600', fontFamily: 'Open Sans', textAlign: 'left', marginTop:'2%' }}>Min</div>
                                        <div></div>
                                        <div className='dataCardValue' style={{ fontSize: '24px', fontWeight: '700', fontFamily: 'Open Sans', textAlign: 'left' }}>{selectedDataset.minimum < 0
                                        ? "0%"
                                        : `${Math.round(selectedDataset.minimum * 100)}%`}</div>
                                    </div>
                                    <div></div>
                                    <div className='WSboxInnerSatellite' style={{ fontSize: '16px', fontWeight: '700', fontFamily: 'Open Sans', textAlign: 'center' }}>
                                    <div className='dataCardTitle' style={{ fontSize: '18px', fontWeight: '600', fontFamily: 'Open Sans', textAlign: 'left',marginTop:'2%' }}>Max</div>
                                        <div></div>
                                        <div className='dataCardValue' style={{ fontSize: '24px', fontWeight: '700', fontFamily: 'Open Sans', textAlign: 'left' }}>{selectedDataset.maximum < 0
                                        ? "0%"
                                        : `${Math.round(selectedDataset.maximum * 100)}%`}</div></div>
                                    <div></div>
                                </div>
                            </div>

                        </div>
                        
                    ) : (
                        <p>No Data Yet</p>
                    ) }
                    </div>

                    <div></div>
                    <div className='notificationsWrapper'>
                        <NotificationsSatellite></NotificationsSatellite>
                    </div>
                </div>
                
            </div>
        </div>
    );
};

export default Satellite;