import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { LineChart } from "@mui/x-charts/LineChart";

const LineChartSat = () => {
  const { t } = useTranslation();

  // Function to calculate chart width based on screen size
  const calculateWidth = () => {
    if (window.innerWidth > 2400) return 1200; // Large screens
    //if (window.innerWidth > 968) return 600; // Medium screens
    return Math.min(window.innerWidth - 20, 700); // Small screens
  };

  const [chartWidth, setChartWidth] = useState(calculateWidth());

  useEffect(() => {
    const handleResize = () => {
      setChartWidth(calculateWidth());
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  // Translated labels
  const xLabels = [
    t("January"),
    t("February"),
    t("March"),
    t("April"),
    t("May"),
    t("June"),
    t("July"),
    t("August"),
    t("September"),
    t("October"),
    t("November"),
    t("December"),
  ];

  return (
    <div style={{ maxWidth: "100%", margin: "0 auto" }}>
      <LineChart
        xAxis={[
          {
            scaleType: "point",
            data: xLabels,
            grid: {
              stroke: "rgba(0, 0, 0, 0.2)", // Lighter transparent lines
              lineDash: [5, 5], // Dashed grid lines
            },
          },
        ]}
        yAxis={[
          {
            grid: {
              stroke: "rgba(0, 0, 0, 0.2)", // Lighter transparent lines
              lineDash: [5, 5], // Dashed grid lines
            },
          },
        ]}
        series={[
          {
            data: [65, 59, 80, 81, 56, 55, 40, 52, 50, 55, 60, 47],
            color: "rgb(60, 179, 113)", // Ensure the graph line is not overpowering the grid
          },
        ]}
        width={chartWidth} // Dynamically calculated width
        height={300}
      />
    </div>
  );
};

export default LineChartSat;
